import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { KeyboardArrowDown } from '@mui/icons-material';
import Images from '../utils/Images';
import { useNavigate } from 'react-router-dom';

const StoreHeader = () => {
  const [store, setStore] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    // const userDataLogin = JSON.parse(localStorage.getItem('userDataLogin'));
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    console.log('Store data', store);
  }, []);

  const handleProfileClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/profileScreen');
    } else {
      navigate('/mobileNumber');
    }
  };

  return (
    <Box className="storeHeader">
      <Box onClick={() => navigate('/advertiseScreen')}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StoreIcon className='storeIcon' />
              <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#0E4961', marginTop: '2px' }}>{store?.name}</Typography>
          </Box>
          <Typography className="storeAddress2">{store?.address}</Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography className="changeLocation">Change Locations</Typography>
            <KeyboardArrowDown sx={{ fontSize: '18px' }} />
          </Box>
      </Box>
      <Box>
          <img onClick={handleProfileClick} className='profileImage' src={ userData?.profileImage || Images.profileHead } alt="" />
      </Box>
    </Box>
  )
}

export default StoreHeader;
