import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Hidden, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { API } from '../../utils/Api';
import Images from '../../utils/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CelebrationOutlined } from '@mui/icons-material';
import { Player } from '@lottiefiles/react-lottie-player';

const CouponsScreen = () => {
  const [joiningCoupons, setJoiningCoupons] = useState([]);
  const [validCoupons, setValidCoupons] = useState([]);
  const [applicableCoupons, setApplicableCoupons] = useState([]);
  const [birthdayCoupons, setBirthdayCoupons] = useState([]);
  const [referralCoupon, setReferralCoupon] = useState([]);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const cartAmount = location.state.cartAmount;
  const navigate = useNavigate();
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [fetchingCoupons, setFetchingCoupons] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [errorText, setErrorText] = useState('');
  const [applyingBtn, setApplyingBtn] = useState({});
  const [applyingCouponRefresh, setApplyingCouponRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCouponCode('');
    setErrorText('');
  }, []);
  
  useEffect(() => {
    const fetchCoupons = async () => {
      setFetchingCoupons(true);
      if (!token || !outletData?._id) {
        return;
      }
      try {
        const response = await axios.get(`${API.BASE_URL}coupons/getUserApplicableCoupons/${outletData?._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { birthdayCoupon, joiningCoupon, validCoupons, applicableCoupons, referralCoupon } = response.data.data.totalCoupons;
        setBirthdayCoupons(birthdayCoupon);
        setJoiningCoupons(joiningCoupon);
        setValidCoupons(validCoupons);
        setReferralCoupon(referralCoupon);
        setApplicableCoupons(applicableCoupons);
        setFetchingCoupons(false);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      } finally{
        setFetchingCoupons(false);
      }
    };
    fetchCoupons();
  }, []);

  const applyCoupon = async (couponId) => {
    window.scrollTo(0, 0);
    setApplyingCouponRefresh(true);
    setApplyingBtn((prevState) => ({
      ...prevState,
      [couponId]: true
    }))
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/applyCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('Coupon applied successfully:', response.data);
      // setRefreshTrigger(!refreshTrigger);
      toast.success("Coupon applied successfully!!!");
      // navigate('/cartScreen');
      setApplyingCouponRefresh(false);
      navigate('/cartScreen', { state: { couponApplied: true } });
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error(error.response.data.error);
    } finally {
      setApplyingBtn((prevState) => ({
        ...prevState,
        [couponId]: false
      }))
      setApplyingCouponRefresh(true);
    }
  };

  const removeCoupon = async (couponId) => {
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/removeCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Coupon removed successfully:', response.data);
      // setRefreshTrigger(!refreshTrigger);
    } catch (error) {
      console.error('Error removing coupon:', error);
    }
  };

  const applyCouponButton = async () => {
    setErrorText('');
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/applyCoupon`, { 
        "couponCode": couponCode
       }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('Coupon applied successfully:', response.data);
      toast.success("Coupon applied successfully!!!");
      navigate('/cartScreen', { state: { couponApplied: true } });
    } catch (error) {
      console.error('Error applying coupon:', error);
      // toast.error(error.response.data.error || error?.response?.data?.data?.title);
      setErrorText(error?.response?.data?.error || error?.response?.data?.data?.title);
    }
  };
  
  const handleInputChange = (newValue) => {
    setCouponCode(newValue);
  };

  return (
    <>
    <Box className="couponsScreen">
      <Hidden mdUp>
        <Box className="coupons-title">
          <div className='couponHeading'>
            <Typography className='headingText'>APPLY COUPON</Typography>
          </div>
          <Typography className='headingCart'>Your Cart: ₹{cartAmount}</Typography>
        </Box>
      </Hidden>
      {applyingCouponRefresh ? (
        <Box sx={{ height: '100%', marginTop: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          {/* <img src={Images.applyingCouponAnimation} /> */}
          <Player autoplay loop={true} keepLastFrame={true} src={Images.applyingCouponAnimation} style={{ height: '250px'}} />
          <Typography variant='h5' className='bestTitle'>Applying Your Coupon</Typography>
        </Box>
      ) : (
        <>
          <Box className="couponContainer">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter Coupon Code"
                value={couponCode}
                // onChange={handleInputChange}
                onChange={(e) => handleInputChange(e.target.value.toUpperCase())} 
                sx={{
                  backgroundColor: '#fff',
                  '& fieldset': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#E0E0E0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E0E0E0',
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={applyCouponButton}
                sx={{
                  backgroundColor: '#1E9CED',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  height: '56px',
                  width: '120px',
                  '&:hover': {
                    backgroundColor: '#1E9CED',
                  },
                }}
              >
                Apply
              </Button>
            </Box>
            {errorText && (
              <Typography variant='caption' sx={{ color: 'red', fontWeight: '500' }}>{errorText}</Typography>
            )}
            {fetchingCoupons? (
              <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '25px' }}>
                {/* <CircularProgress /> */}
                <Player autoplay loop={true} keepLastFrame={true} src={Images.applyingCouponAnimation} style={{ height: '250px'}} />
                <Typography variant='h5' className='bestTitle' sx={{ marginTop: '10px' }}>Fetching Coupons</Typography>
              </Box>
            ) : 
            (
              <>
              {birthdayCoupons?.length > 0 && (
                <>
                  <Typography className='bestTitle'>Here's your birthday gift from us <CelebrationOutlined /></Typography>
                  {birthdayCoupons?.map(coupon => (
                    <Box className="coupon birthdayCoupon">
                      <Grid container>
                        <Grid item xs={10} className='couponDetailSection'>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableCategories.length > 0 ? `Applicable to categories: ${coupon?.applicableCategories.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableProducts.length > 0 ? `Applicable to: ${coupon?.applicableProducts.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <div className='couponDetail'>
                            <Typography className='couponName'>{coupon.couponCode}</Typography>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                            )}
                            <Typography className='couponDesc'>{coupon.description}</Typography>
                            <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                              {coupon.validityEndDate ? (
                                <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                  Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                </Typography>
                              ) : (
                                <span style={{ flex: 1 }}></span>
                              )}
                              <Button
                                className='applyButton'
                                onClick={() => applyCoupon(coupon._id)}
                                disabled={applyingBtn[coupon._id]}
                              >
                                {applyingBtn[coupon._id] ? 'Applying' : 'Apply'}
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={2} className='couponDiscountSection'>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )} 
                          {coupon?.discountPrice && (
                            <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
              {/* <Typography className='bestTitle'>Joining Coupons</Typography> */}
              {joiningCoupons?.length > 0 && (
                <>
                  <Typography className='bestTitle'>Joining Rewards</Typography>
                  {joiningCoupons?.map(coupon => (
                    <Box className="coupon">
                      <Grid container>
                        <Grid item xs={10} className='couponDetailSection'>
                          {coupon?.applicableCategories.length > 0 && (
                            <Typography className='couponApplyTo'>
                              {coupon?.applicableCategories.length > 0 ? `Applicable to categories: ${coupon?.applicableCategories.map(product => product.name).join(', ')}` : ''}
                            </Typography>
                          )}
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableProducts.length > 0 ? `Applicable to: ${coupon?.applicableProducts.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <div className='couponDetail'>
                            <Typography className='couponName'>{coupon.couponCode}</Typography>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                            )}
                            <Typography className='couponDesc'>{coupon.description}</Typography>
                            <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                              {coupon.validityEndDate ? (
                                <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                  Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                </Typography>
                              ) : (
                                <span style={{ flex: 1 }}></span>
                              )}
                              <Button
                                className='applyButton'
                                onClick={() => applyCoupon(coupon._id)}
                                disabled={applyingBtn[coupon._id]}
                              >
                                {applyingBtn[coupon._id] ? 'Applying' : 'Apply'}
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={2} className='couponDiscountSection'>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )} 
                          {coupon?.discountPrice && (
                            <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
              {referralCoupon?.length > 0 && (
                <>
                  <Typography className='bestTitle'>Your Referral Rewards</Typography>
                  {referralCoupon?.map(coupon => (
                    <Box className="coupon">
                      <Grid container>
                        <Grid item xs={10} className='couponDetailSection'>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableCategories.length > 0 ? `Applicable to categories: ${coupon?.applicableCategories.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableProducts.length > 0 ? `Applicable to: ${coupon?.applicableProducts.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <div className='couponDetail'>
                            <Typography className='couponName'>{coupon.couponCode}</Typography>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                            )}
                            <Typography className='couponDesc'>{coupon.description}</Typography>
                            <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                              {coupon.validityEndDate ? (
                                <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                  Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                </Typography>
                              ) : (
                                <span style={{ flex: 1 }}></span>
                              )}
                              <Button
                                className='applyButton'
                                onClick={() => applyCoupon(coupon._id)}
                                disabled={applyingBtn[coupon._id]}
                              >
                                {applyingBtn[coupon._id] ? 'Applying' : 'Apply'}
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={2} className='couponDiscountSection'>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )} 
                          {coupon?.discountPrice && (
                            <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
              {applicableCoupons?.length > 0 && (
                <>
                  <Typography className='bestTitle'>Applicable Coupons</Typography>
                  {applicableCoupons?.map(coupon => (
                    <Box className="coupon">
                      <Grid container>
                        <Grid item xs={10} className='couponDetailSection'>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableCategories.length > 0 ? `Applicable to categories: ${coupon?.applicableCategories.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableProducts.length > 0 ? `Applicable to: ${coupon?.applicableProducts.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <div className='couponDetail'>
                            <Typography className='couponName'>{coupon.couponCode}</Typography>
                            {/* <Typography className='couponOff'>Save {coupon.discountPercent}% with this coupon</Typography> */}
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                            )}
                            <Typography className='couponDesc'>{coupon.description}</Typography>
                            <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                              {coupon.validityEndDate ? (
                                <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                  Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                </Typography>
                              ) : (
                                <span style={{ flex: 1 }}></span>
                              )}
                              <Button
                                className='applyButton'
                                onClick={() => applyCoupon(coupon._id)}
                                disabled={applyingBtn[coupon._id]}
                              >
                                {applyingBtn[coupon._id] ? 'Applying' : 'Apply'}
                              </Button>
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={2} className='couponDiscountSection'>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.discountPrice && (
                            <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
              {validCoupons?.length > 0 && (
                <>
                  <Typography className='bestTitle'>Other available offers</Typography>
                  {validCoupons?.map(coupon => (
                    <Box className="coupon notApplicable">
                      <Grid container>
                        <Grid item xs={10} className='couponDetailSection'>
                          {/* <Typography className='couponApplyTo'>
                            {coupon?.applicableCategories.length > 0 ? `Applicable to categories: ${coupon?.applicableCategories.map(product => product.name).join(', ')}` : ''}
                          </Typography>
                          <Typography className='couponApplyTo'>
                            {coupon?.applicableProducts.length > 0 ? `Applicable to: ${coupon?.applicableProducts.map(product => product.name).join(', ')}` : ''}
                          </Typography> */}
                          <div className='couponDetail'>
                            <Typography className='couponTag'>{coupon.couponMessage}</Typography>
                            <Typography className='couponName'>{coupon.couponCode}</Typography>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                            )}
                            <Typography className='couponDesc'>{coupon.description}</Typography>
                            <Box className="validAndButton">
                              {coupon.validityEndDate && (
                                <Typography className="validity">
                                  Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                </Typography>
                              )}
                            </Box>
                          </div>
                        </Grid>
                        <Grid item xs={2} className='couponDiscountSection'>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='verticalFlatOff'>FREE!!!</Typography>
                          )} 
                          {coupon?.discountPrice && (
                            <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
    </>
  );
};

export default CouponsScreen;