import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { API } from '../utils/Api';
import { useDispatch } from 'react-redux';
import { setActivePickTime, setNewDuration } from '../store/Cart/timeSlice';
import Images from '../utils/Images';
import { Player } from '@lottiefiles/react-lottie-player';

const PickUpTime = ({ selectedTime }) => {
  const [activeTime, setActiveTime] = useState('');
  const selectedOrderType = localStorage.getItem('selectedOrderType');
  const [makingTimeData, setMakingTimeData] = useState();
  const [newTime, setNewTime] = useState('');
  const [arrivalTimeDurationInMinutes, setArrivalTimeDurationInMinutes] = useState(0);
  const [baseDuration, setBaseDuration] = useState(0);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [fetchingTimes, setFetchingTimes] = useState(true);
  const [initialReadyTime, setInitialReadyTime] = useState('');
  const userHasSelectedTime = useRef(false);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  
  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
  };

  const updateTimes = async () => {
    setFetchingTimes(true);
    try {
      const responseForMakingTime = await axios.get(`${API.BASE_URL}cart/getMakingTime`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const makingTime = parseInt(responseForMakingTime.data.data.makingTimeDuration);
      const currentTime = new Date();
      const newCalculatedTime = new Date(currentTime.getTime() + makingTime * 60000);
      const timeIncrements = [0, 5, 10, 15];
  
      const slots = timeIncrements.map(increment => {
        const slotTime = new Date(newCalculatedTime.getTime() + increment * 60000);
        return {
          formatted: formatTime(slotTime),
          increment: increment
        };
      });
  
      setBaseDuration(makingTime);
      setArrivalTimeDurationInMinutes(makingTime);
      setNewTime(formatTime(newCalculatedTime));
      setAvailableSlots(slots);
  
      if (selectedOrderType === "DELIVERY") {
        // Always set the first slot as the active time for DELIVERY
        setActiveTime(slots[0].formatted);
        setInitialReadyTime(slots[0].formatted);
        // dispatch()
        dispatch(setActivePickTime(slots[0].formatted));
        dispatch(setNewDuration(makingTime + slots[0].increment));
      } else {
        // Reset active time if a minute has passed since the last selection
        if (!userHasSelectedTime.current) {
          setActiveTime('');
          setInitialReadyTime(slots[0].formatted); 
          dispatch(setActivePickTime(''));
        } else {
          // If a time is already selected, reset it to the first slot if more than a minute has passed
          const selectedSlot = slots.find(slot => slot.formatted === activeTime);
          if (!selectedSlot) {
            setActiveTime(slots[0].formatted);
            setInitialReadyTime(slots[0].formatted); 
            dispatch(setActivePickTime(slots[0].formatted));
            dispatch(setNewDuration(makingTime + slots[0].increment));
          }
        }
      }
      setFetchingTimes(false);
    } catch (error) {
      console.error('Error in making time API', error.response);
    } finally {
      setFetchingTimes(false);
    }
  };
  
  // const updateTimes = async () => {
  //   try {
  //     const responseForMakingTime = await axios.get(`${API.BASE_URL}cart/getMakingTime`, {
  //       headers: { Authorization: `Bearer ${token}` }
  //     });
  //     const makingTime = parseInt(responseForMakingTime.data.data.makingTimeDuration);
  //     const currentTime = new Date();
  //     const newCalculatedTime = new Date(currentTime.getTime() + makingTime * 60000);
  //     const timeIncrements = [0, 5, 10, 15];

  //     const slots = timeIncrements.map(increment => {
  //       const slotTime = new Date(newCalculatedTime.getTime() + increment * 60000);
  //       return {
  //         formatted: formatTime(slotTime),
  //         increment: increment
  //       };
  //     });

  //     setBaseDuration(makingTime);
  //     setArrivalTimeDurationInMinutes(makingTime);
  //     setNewTime(formatTime(newCalculatedTime));
  //     setAvailableSlots(slots);

  //     // Reset active time if a minute has passed since the last selection
  //     if (!userHasSelectedTime.current) {
  //       setActiveTime('');
  //       setInitialReadyTime(slots[0].formatted); 
  //       dispatch(setActivePickTime(''));
  //     } else {
  //       // If a time is already selected, reset it to the first slot if more than a minute has passed
  //       const selectedSlot = slots.find(slot => slot.formatted === activeTime);
  //       if (!selectedSlot) {
  //         setActiveTime(slots[0].formatted);
  //         setInitialReadyTime(slots[0].formatted); 
  //         dispatch(setActivePickTime(slots[0].formatted));
  //         dispatch(setNewDuration(makingTime + slots[0].increment));
  //       }
  //     }

  //   } catch (error) {
  //     console.error('Error in making time API', error.response);
  //   }
  // };

  useEffect(() => {
    updateTimes();
    const intervalId = setInterval(updateTimes, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (time, increment) => {
    userHasSelectedTime.current = true;
    const newDuration = baseDuration + increment;
    setActiveTime(time.formatted);
    console.log('Selected time:', time.formatted, 'with total duration:', newDuration);
    setArrivalTimeDurationInMinutes(newDuration);
    dispatch(setActivePickTime(time.formatted));
    dispatch(setNewDuration(newDuration)); 
  };

  return (
    <Box className="pickUpTimeComponent">
      <Box className="firstBox">
        <img src={Images.pickUpIcon} className="pickIcon" alt="pickUpIcon" />
        <Typography className="pickUpText">Set Up Pick Up Time</Typography>
      </Box>
      {fetchingTimes ? (
        <Box className="secondBg" sx={{ textAlign: 'center' }}>
          {/* <img src={Images.timeLoading} style={{ height: '80px' }} /> */}
          <Player autoplay loop={true} keepLastFrame={true} src={Images.timeLoading} style={{ height: '100px'}} />
          <Typography className='availableSlot'>Fetching Available Time Slots</Typography>
        </Box>
      ) : (
        <Box className="secondBg">
          <Box className="secondBox">
            <Typography className='readyTime'>Ready Time: <span style={{ fontWeight: '600' }}>{activeTime}</span> </Typography>
            <Typography className='availableSlot'>Available Slots</Typography>
          </Box>
          <Box className="timeSlots">
            <Grid container spacing={2}>
            {availableSlots.map((slot, index) => (
              <Grid item xs={6} key={index}>
                <Typography 
                  className={`time ${activeTime === slot.formatted ? 'active' : ''}`} 
                  onClick={() => handleClick(slot, slot.increment)}
                  sx={{ textAlign: 'center' }}
                >
                  {slot.formatted}
                </Typography>
              </Grid>
            ))}
            </Grid>
          </Box>
        </Box>
      )}
      
    </Box>
  );
};

export default PickUpTime;
