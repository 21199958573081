import React, { useEffect, useState } from 'react';
import { Box, Grid, Drawer, IconButton, Typography, CircularProgress, Stack } from '@mui/material';
import { CloseOutlined, StarRounded } from '@mui/icons-material';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import AddonIconComponent from '../../components/AddonIconComponent';
import AddonIconComponent from './AddonIconComponent';
import Images from '../utils/Images';

const ProductDrawer = ({
  open,
  onClose,
  selectedProduct,
  handleAddToCart,
  isFavorited,
  handleFavoriteClick,
  quantity,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  selectedAddons,
  handleAddonSelect,
  totalPrice,
  totalMrpPrice,
  offerPercentage,
  addingToCart,
}) => {

  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  
  return (
    <Drawer open={open} onClose={onClose} anchor="bottom">
      <Box className="productDrawer">
        <IconButton 
          onClick={onClose}
          sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
        >
          <CloseOutlined sx={{ fontSize: '12px' }} />
        </IconButton>
        <Grid container>
          <Grid item xs={5} sx={{ justifyContent: 'center'}}>
            <Box className="imageBox">
              <img className="image" src={selectedProduct?.displayImageUrl || selectedProduct?.displayImage} alt="Product" />
            </Box>
          </Grid>
          <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
            {isFavorited ? (
              <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} />
            ) : (
              <FavoriteBorderRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} />
            )}
            <Box className="productDetails">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
              </Box>
              <Typography className="productInfo">{selectedProduct?.description}</Typography>
            </Box> 
          </Grid>
        </Grid>
        {selectedProduct && (
          <>
            {selectedProduct.hasAddon && selectedProduct.addons && selectedProduct.addons.length > 0 && (
              <Box className="addonsBox">
                {selectedProduct.addons.map((addon) => (
                  <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className="addonTitle">{addon.addonTitle}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container justifyContent="flex-end">
                        {addon.addonValues.slice().filter(addon => addon.status !== 'INACTIVE').map((addonValue) => {
                          const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                          const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                          return (
                            <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Box 
                                className="addonItem" 
                                onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                                sx={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <Box sx={{ flex: '1 1 auto' }} />
                                  <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#00A4E2' }}> {addonValue.subLabel} </Typography>
                                  {icon ? (
                                    <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                                  ) : (
                                    <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                                  )}
                                  <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#118E06', padding: '3px 0 7px 0' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
          </>
        )}
        <Box className="priceAndButton">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Typography className="productPrice">₹{totalPrice} <span className='productMrp'> ₹{totalMrpPrice}</span></Typography> */}
            <Typography className="productPrice">₹{parseFloat(totalPrice) * parseInt(quantity)}<span className='productMrp'> ₹{parseFloat(totalMrpPrice) * parseInt(quantity)}</span></Typography>
            <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
          </Box>
          <Box className="addRemoveItem">
            <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
            <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
            <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
          </Box>
        </Box>
        {outletData?.isEventOutlet === selectedProduct?.isEventProduct ? (
          addingToCart ? (
            <Box className="button">
              <CircularProgress size={18} sx={{ color: '#fff' }} /> Adding to cart
            </Box>
          ) : (
            <Box
              className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"}
              onClick={!selectedProduct?.isExhausted ? handleAddToCart : null}
            >
              {selectedProduct?.isExhausted ? "Out Of Stock" : "ADD"}
            </Box>
          )
        ) : (
          <Box className="exhaustedButton">
            <span style={{ fontSize: '16px' }}>Order this at our store</span> 
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default ProductDrawer;

{/* <ProductDrawer
  open={!!selectedProduct}
  onClose={handleCloseModal}
  selectedProduct={selectedProduct}
  handleAddToCart={handleAddToCart}
  isFavorited={isFavorited}
  handleFavoriteClick={handleFavoriteClick}
  quantity={quantity}
  handleIncreaseQuantity={handleIncreaseQuantity}
  handleDecreaseQuantity={handleDecreaseQuantity}
  selectedAddons={selectedAddons}
  handleAddonSelect={handleAddonSelect}
  totalPrice={totalPrice}
  totalMrpPrice={totalMrpPrice}
  offerPercentage={offerPercentage}
  addingToCart={addingToCart}
/> */}

{/* <Drawer
  open={!!selectedProduct}
  onClose={handleCloseModal}
  anchor="bottom"
>
  <Box className="productDrawer">
    <IconButton 
      onClick={handleCloseModal}
      sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
    >
      <CloseOutlined sx={{ fontSize: '12px' }} />
    </IconButton>
    <Grid container>
      <Grid item xs={5} sx={{ justifyContent: 'center'}}>
        <Box className="imageBox">
          <img className="image" src={selectedProduct?.displayImageUrl} alt="Product" />
        </Box>
      </Grid>
      <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
        <Box className="drawerVegAndFavourite">
          <Box sx={{ display: 'flex' }}>
            <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings ? selectedProduct.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
            <>
              {selectedProduct?.foodType === 'VEG/VEGAN' ? (
                <Stack direction={'row'}>
                  <img className='vegNonveg' src={Images.veg} alt='veg' />
                  <img className='vegNonveg' src={Images.vegan} alt='vegan' />
                </Stack>
              ) : (
                <img className='vegNonveg' src={selectedProduct?.foodType === 'VEG' ? Images.veg : (selectedProduct?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct?.foodType === 'VEG' ? 'veg' : (selectedProduct?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
              )}
            </>
          </Box>
          {isFavorited ? ( <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> ) : ( <FavoriteBorderRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} /> )}
        </Box>
        <Box className="productDetails">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
          </Box>
          <Typography className="productInfo" sx={{ paddingBottom: '0px' }}>{selectedProduct?.calories} cal {selectedProduct?.weight}<span style={{ textTransform: 'capitalize' }}>{selectedProduct?.weightUnit}</span> </Typography>
          <Typography className="productInfo">{selectedProduct?.description}</Typography>
        </Box> 
      </Grid>
    </Grid>
    {selectedProduct && (
      <>
        {selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct?.addons?.length > 0 && (
          <Box className="addonsBox">
            {selectedProduct?.addons?.map((addon) => (
              <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="addonTitle">{addon.addonTitle}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container justifyContent="flex-end">
                    {addon.addonValues.map((addonValue) => {
                      const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                      const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                      return (
                        <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Box 
                            className="addonItem" 
                            onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Box sx={{ flex: '1 1 auto' }} />
                              <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#00A4E2' }}> {addonValue.subLabel} </Typography>
                              {icon ? (
                                <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                              ) : (
                                <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                              )}
                              <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#118E06' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                            </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </>
    )}
    <Box className="priceAndButton">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography className="productPrice">₹{parseFloat(totalPrice) * parseInt(quantity)}<span className='productMrp'> ₹{parseFloat(totalMrpPrice) * parseInt(quantity)}</span></Typography>
        <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
      </Box>
      <Box className="addRemoveItem">
        <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
        <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
        <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
      </Box>
    </Box>
    {addingToCart ? (
      <Box className="button"><CircularProgress size={18} sx={{ color: '#fff', marginRight: '6px' }} /> Adding to cart</Box>
    ) : (
      <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"} onClick={!selectedProduct?.isExhausted ? () => handleAddToCart() : null}>{selectedProduct?.isExhausted ? "Out Of Stock" : "ADD"}</Box>
    )}
  </Box>
</Drawer> */}